import React from "react"
import Layout from "../../components/Layout"

import Strada from "../../components/strada"
export default function TrackHero() {
  return (
    <>
      <Layout>
        <Strada />
      </Layout>
    </>
  )
}
